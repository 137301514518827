import * as React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';

import { Footer } from './footer';
import { Nav } from './nav';

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps): JSX.Element {
  return (
    <>
      <Helmet>
        <script
          src={withPrefix('scripts/lfeeder-tracker.js')}
          type="text/javascript"
        />
      </Helmet>
      <div className="relative flex flex-col min-h-screen font-sans antialiased text-gray-700 bg-light fill-available"></div>
    </>
  );
}

export { Layout };
